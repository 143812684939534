import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// vue-router
import router from "./router"

import moment from 'moment'
import API from './services/api';

Vue.prototype.moment = moment

Vue.config.productionTip = false
Vue.prototype.$http = Axios;
Vue.prototype.$api = new API();

Vue.use(VueSweetalert2);

const token = localStorage.getItem('token');
Vue.prototype.$base_url = "https://api-dev-mimo.victorystudio.us/public/";
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
