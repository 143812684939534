import axios from 'axios';
class API {
    apiService = null;
  
    // router = null;
  
    // token = 'admin';
  
    withRedirect = true
  
    constructor() {
      const baseURL = 'https://api-dev.mimologistics.id/';
      // const baseURL = 'http://localhost:8000/';
      this.apiService = axios.create({
        baseURL: baseURL || baseURL,
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      });
    }
  
    
    request(method, url, payload, callback) {
      // Create instance

    // Set the AUTH token for any request
    this.apiService.interceptors.request.use(function (config) {
      const token = localStorage.getItem('token');
      config.headers.Authorization =  token ? `${token}` : '';
      return config;
    });
      const config = {
        method,
        url,
      };
      const token = localStorage.getItem("token");
      if (token) Object.assign(config, { headers: { Authorization: `Bearer ${token}` } });
      if (method !== 'DELETE' || method !== 'GET') Object.assign(config, { data: payload });
  
      this.apiService.request(config)
        .then((response) => {
          if (callback) callback(response.status, response.data.data, response.data.message, response.data);
        })
        .catch((error) => {
          const { response } = error;
          console.log("waw")
          this.unAuthorize(response.status);
          if (callback) callback(response.status, response.data.data, response.data.message, response.data);
        });
    }
  
    get(url, callback) { this.request('GET', url, null, callback); }
  
    post(url, payload, callback) { this.request('POST', url, payload, callback); }
  
    put(url, payload, callback) { this.request('PUT', url, payload, callback); }
  
    patch(url, payload, callback) { this.request('PATCH', url, payload, callback); }
  
    delete(url, callback) { this.request('DELETE', url, null, callback); }
  
    unAuthorize(status) {
      console.log(status)
      if (status === 401) {
          localStorage.clear()
          window.location.reload()
          this.$router.push("/login");
      }
    }
  }
  
export default API;