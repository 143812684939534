import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
const base_url = "https://api-dev.mimologistics.id/"
// const base_url = "http://localhost:8000/"
export default new Vuex.Store({
      // authentication
      state: {
      status: '',
      token: localStorage.getItem('token') || '',
      user: {},
      // end of authentication

      // customer pages
      mypackages: [], 
      mynotification: [],
      uploadedPackages: {},
      profileCustomers: {},
      // end of customer

      // admin pages
      allpackages: [], 
      detailpackages: {},
      updatestatus: {},
      invoicespackages: [],
      adminnotification: [],
      paymentstatus: {},
      // end of admin pages

      // warehouse pages
        warehousepackages: [],
        warehousedetailpackages: {},
      // end of warehouse page
    },
    mutations: {
        // authentication
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token, user) {
            state.status = 'success'
            state.token = token
            state.user = user
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
        // end of authentication

        // customer pages
        set_profile(state, profileCustomer){
          state.profileCustomers = profileCustomer
          console.log(profileCustomer)
        },
        set_mypackages(state, mypackages) {
          state.mypackages = mypackages;
        },
        set_mynotification(state, mynotification) {
          state.mynotification = mynotification;
        },
        uploaded_packages(state, uploadedPackages) {
          state.uploadedPackages = uploadedPackages
        },
        // end of customer pages

        // admin pages
        set_allpackages(state, allpackages) {
          state.allpackages = allpackages;
        },
        set_detailpackages(state, detailpackages) {
          state.detailpackages = detailpackages;
        },
        set_updatestatus(state, updatestatus) {
          state.updatestatus = updatestatus;
        },
        set_invoicespackages(state, invoicespackages) {
          state.invoicespackages = invoicespackages;
        },
        set_adminnotification(state, adminnotification) {
          state.adminnotification = adminnotification;
        },
        // end of admin pages

        // warehouse pages
        set_warehousepackages(state, warehousepackages) {
          state.warehousepackages = warehousepackages;
        },
        set_warehousedetailpackages(state, warehousedetailpackages) {
          state.warehousedetailpackages = warehousedetailpackages;
        },
        // end of wahrehouse pages
    },
    actions: {
      // authentication
      login({ commit }, user) {
          return new Promise((resolve, reject) => {
            commit('auth_request')
            axios({ url: base_url + "login", data: user, method: 'POST' })
              .then(resp => {
                console.log(resp)
                console.log(resp.data.data.data.user.token)
                const token = resp.data.data.data.user.token
                const role = resp.data.data.data.user.role
                const user = resp.data.data.data.user
                localStorage.setItem('token', token)
                localStorage.setItem('role', role)
                // Add the following line:
                axios.defaults.headers.common['Authorization'] = token
                commit('auth_success', token, user)
                resolve(resp)
              })
              .catch(err => {
                commit('auth_error')
                localStorage.removeItem('token')
                console.log(err)
                reject(err)
              })
          })
      },
      register({ commit }, user) {
          return new Promise((resolve, reject) => {
            commit('auth_request')
            axios({ url: base_url + "register", data: user, method: 'POST' })
              .then(resp => {
                const token = resp.data.token
                const user = resp.data.user
                localStorage.setItem('token', token)
                // Add the following line:
                axios.defaults.headers.common['Authorization'] = token
                commit('auth_success', token, user)
                resolve(resp)
              })
              .catch(err => {
                commit('auth_error', err)
                localStorage.removeItem('token')
                reject(err)
              })
          })
      },
      logout({ commit }) {
          return new Promise((resolve) => {
            commit('logout')
            localStorage.removeItem('token')
            localStorage.removeItem('role')
            delete axios.defaults.headers.common['Authorization']
            resolve()
          })
      },
      // end of authentication

      // customer pages
      getProfileCustomers({commit}){
        axios.get(base_url + "user").then((res) => {
          console.log(res.data)
          commit("set_profile", res.data)
        })
      },
      postPackages({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios({ 
            url: base_url + "customer-package", 
            data: data, method: 'POST', 
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(resp => {
              console.log(resp.data)
              commit('uploaded_packages', resp.data.data.customer_packages)
              resolve(resp)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
      },
      postBanner({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios({ 
            url: base_url + "banner", 
            data: data, method: 'POST', 
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(resp => {
              console.log(resp.data)
              commit('uploaded_packages', resp.data.banner)
              resolve(resp)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
      },
      postAddress({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios({ url: base_url + "warehouse-address", data: data, method: 'POST' }).then(resp => {
              console.log(resp.data)
              commit('uploaded_packages', resp.data.blog)
              resolve(resp)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
      },
      postBlog({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios({ 
            url: base_url + "blog", 
            data: data, method: 'POST', 
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(resp => {
              console.log(resp.data)
              commit('uploaded_packages', resp.data.blog)
              resolve(resp)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
      },
      postServices({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios({ 
            url: base_url + "service", 
            data: data, method: 'POST', 
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(resp => {
              console.log(resp.data)
              commit('uploaded_packages', resp.data.blog)
              resolve(resp)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
      },
      postPromotion({ commit }, data) {
        return new Promise((resolve, reject) => {
          axios({ 
            url: base_url + "promotion", 
            data: data, method: 'POST', 
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(resp => {
              console.log(resp.data)
              commit('uploaded_packages', resp.data.promotion)
              resolve(resp)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
      },
      updateBanner({ commit }, data, id) {
        return new Promise((resolve, reject) => {
          axios({ 
            url: base_url + "banner/" + id, 
            data: data, method: 'PATCH', 
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(resp => {
            console.log(data)
              console.log(resp.data)
              commit('uploaded_packages', resp.data.banner)
              resolve(resp)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
      },
      updatePromotion({ commit }, formData) {
        console.log(formData.id + " :data.id")
        return new Promise((resolve, reject) => {
          axios({ 
            url: base_url + "promotion/" + formData.id, 
            data: formData, method: 'PATCH', 
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(resp => {
            // console.log(data)
              // console.log(resp.data)
              commit('uploaded_packages', resp.data.banner)
              resolve(resp)
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
      },
      getMyPackages({ commit }) {
        axios.get( base_url + "customer-package/customer").then((res) => {
          console.log(res.data.data.customer_package.data);
          commit("set_mypackages", res.data.data.customer_package.data);
        });
      },
      getMyNotification({ commit }) {
        axios.get( base_url + "notification/customer").then((res) => {
          console.log(res.data.data.notification.data);
          commit("set_mynotification", res.data.data.notification.data);
        });
      },
      // end of customer pages

      // admin pages
      getAllPackages({ commit }){
        axios.get( base_url + "customer-package").then((res) => {
          console.log(res.data.data.customer_package.data);
          commit("set_allpackages", res.data.data.customer_package.data);
        });
      },
      getDetailPackages({ commit }, code){
        axios.get( base_url + "customer-package/detail/" + code).then((res) => {
          console.log(res.data.data.customer_package);
          commit("set_detailpackages", res.data.data.customer_package);
        });
      },
      postStatusPackages({ commit }, data){
        axios.post( base_url + "customer-package/approval-status", data).then((res) => {
          console.log(res);
          commit("set_updatestatus", res.data);
        });
      },
      postNotifyInvoices({ commit }, data){
        axios.post( base_url + "send-invoice", data).then((res) => {
          console.log(res);
          commit("set_updatestatus", res.data);
        });
      },
      postSingleInvoices({ commit }, data){
        axios.post( base_url + "package-invoice/single", data).then((res) => {
          console.log(res);
          commit("set_updatestatus", res.data);
        });
      },
      postpaymentStatus({ commit }, data){
        axios.post( base_url + "customer-package/payment-status", data).then((res) => {
          console.log(res);
          commit("set_updatestatus", res.data);
        });
      },
      postTrackingNumber({ commit }, data){
        axios.post( base_url + "customer-package/tracking-number", data).then((res) => {
          console.log(res);
          commit("set_updatestatus", res.data);
        });
      },
      getInvoicesPackages({ commit }, id){
        axios.get( base_url + "package-invoice/" + id).then((res) => {
          console.log(res.data.data.package_invoice);
          commit("set_invoicespackages", res.data.data.package_invoice);
        });
      },
      getAdminNotification({ commit }) {
        axios.get( base_url + "notification/admin").then((res) => {
          console.log(res.data.data.notification.data);
          commit("set_adminnotification", res.data.data.notification.data);
        });
      },
      // end of admin pages

      // warehouse pages
      getWarehousePackages({ commit }){
        axios.get( base_url + "customer-package/warehouse").then((res) => {
          console.log(res.data.data.customer_package.data);
          commit("set_warehousepackages", res.data.data.customer_package.data);
        });
      },
      getWarehouseDetailPackages({ commit }, code){
        axios.get( base_url + "customer-package/warehouse/detail/" + code).then((res) => {
          console.log(res.data.data.customer_package);
          commit("set_warehousedetailpackages", res.data.data.customer_package);
        });
      },
      postStatusChecking({ commit }, data){
        axios.post( base_url + "customer-package/checking-status", data).then((res) => {
          console.log(res);
          commit("set_updatestatus", res.data);
        });
      },
      // end of warehouse packages
    },

    getters: {
        // authentication
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        // end of authentication

        // customer pages
        getProfileCustomers: (state) => state.profileCustomers,
        getMyPackages: (state) => state.mypackages,
        getMyNotification: (state) => state.mynotification,
        // end of customer pages

        // admin pages
        getAllPackages: (state) => state.allpackages,
        getInvoicesPackages: (state) => state.invoicespackages,
        getDetailPackages: (state) => state.detailpackages,
        getAdminNotification: (state) => state.adminnotification,
        // end of admin pages

        // warehouse pages
        getWarehousePackages: (state) => state.warehousepackages,
        getWarehouseDetailPackages: (state) => state.warehousedetailpackages,
        // end of warehouse pages
    }
})