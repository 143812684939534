import Vue from "vue"
import Router from "vue-router"
import store from "../store"; // import the store to check if authenticated
Vue.use(Router);

const router = new Router({
    mode: "history",
    linkActiveClass: "active",
    routes: [

        // homepage
        {
            path: '',
            component: () =>
                import ("../layouts/CustomersLayout.vue"),
            children: [{
                    path: '',
                    name: "Homepage",
                    component: () =>
                        import ("../pages/customers/Homepage.vue")
                },
                {
                    path: '/home',
                    component: () =>
                        import ("../pages/customers/Homepage.vue")
                },
                {
                    path: '/login',
                    name: "Login",
                    component: () =>
                        import ("../pages/customers/Login.vue")
                },
                {
                    path: '/forgot-password',
                    name: "ForgotPassword",
                    component: () =>
                        import ("../pages/customers/ForgotPassword.vue")
                },
                {
                    path: '/about-us',
                    name: "About",
                    component: () =>
                        import ("../pages/customers/About.vue")
                },
                {
                    path: '/services',
                    name: "ServicesCust",
                    component: () =>
                        import ("../pages/customers/Services.vue")
                },
                {
                    path: '/promotions',
                    name: "PromotionCust",
                    component: () =>
                        import ("../pages/customers/Promotion.vue")
                },
                {
                    path: '/promotions/:slug',
                    name: "Promotion",
                    props: true,
                    component: () =>
                        import ("../pages/customers/Detailpromo.vue")
                },
                {
                    path: '/blogs',
                    name: "Blog",
                    component: () =>
                        import ("../pages/customers/Blog.vue")
                },
                {
                    path: '/detail-blog/:slug',
                    name: "Detailblog",
                    props: true,
                    component: () =>
                        import ("../pages/customers/Detailblog.vue")
                },
                {
                    path: '/detail-promo/:slug',
                    name: "Detailpromo",
                    component: () =>
                        import ("../pages/customers/Detailpromo.vue")
                },
                {
                    path: '/contacts',
                    name: "Contact",
                    component: () =>
                        import ("../pages/customers/Contact.vue")
                },
               
                {
                    path: '/register',
                    name: "Register",
                    component: () =>
                        import ("../pages/customers/Register.vue")
                },
            ]
        },

        // customer

        {
            path: '/customers',
            component: () =>
                import ("../layouts/CustomersLoginLayout.vue"),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: 'my-profile',
                    name: 'MyProfile',
                    component: () =>
                        import ("../components/customers/customer-area/MyProfile.vue")
                },
                {
                    path: 'update-profile',
                    name: 'MyProfileForm',
                    component: () =>
                        import ("../components/customers/customer-area/MyProfileForm.vue")
                },
                {
                    path: 'update-password',
                    name: 'MyPasswordForm',
                    component: () =>
                        import ("../components/customers/customer-area/MyPasswordForm.vue")
                },
                {
                    path: 'my-packages',
                    name: 'MyPackages',
                    component: () =>
                        import ("../components/customers/customer-area/MyPackages.vue")
                },
                {
                    path: 'my-notification',
                    name: 'MyNotification',
                    component: () =>
                        import ("../components/customers/customer-area/MyNotification.vue")
                },
                {
                    path: 'submit-packages',
                    name: 'SubmitPackages',
                    component: () =>
                        import ("../components/customers/customer-area/FormSubmitPackages.vue")
                },
                {
                    path: 'submit-packages/:promotion_code',
                    name: 'SubmitPackagesPromotion',
                    props: true,
                    component: () =>
                        import ("../components/customers/customer-area/FormSubmitPackages.vue")
                },
                {
                    path: 'detail-packages/:code',
                    name: 'DetailPackages',
                    component: () => 
                        import ("../components/customers/customer-area/DetailPackages.vue"),
                    props: true
                },
              
            ]
        },

        // finance
        {
            path: '/admin-finance',
            component: () =>
                import ("../layouts/FinanceLayouts.vue"),
            meta: {
                requiresAuth: true
            },
            children: [{
                    path: 'dashboard',
                    component: () =>
                        import ("../pages/admin/Dashboard.vue")
                },
                {
                    path: 'my-account',
                    name: 'MyAccountAdminFinance',
                    component: () =>
                        import ("../components/admin/admin-finance/my-profile-admin.vue")
                },
                {
                    path: 'update-my-account',
                    name: 'MyAccountAdminFinanceForm',
                    component: () =>
                    import ("../components/admin/admin-finance/my-profile-admin-form.vue")
                },
                {
                    path: 'update-my-password',
                    name: 'MyAccountPasswordForm',
                    component: () =>
                    import ("../components/admin/my-profile-password-form.vue")
                },
                {
                    path: 'customer-packages',
                    component: () =>
                        import ("../pages/admin/CustomerPackages.vue")
                },
                {
                    path: 'customer-packages/detail/:code/:id',
                    component: () =>
                        import ("../pages/admin/CustomerPackagesDetail.vue"),
                    props: true
                },
                {
                    path: 'customer-list',
                    component: () =>
                        import ("../pages/admin/CustomerList.vue")
                },
                {
                    path: 'customer-list/detail/:id',
                    props: true,
                    component: () =>
                        import ("../pages/admin/CustomerDetail.vue")
                },
                {
                    path: 'report',
                    component: () =>
                        import ("../pages/admin/Report.vue")
                },
                {
                    path: 'banners',
                    component: () =>
                        import ("../pages/admin/Banners.vue")
                },
                {
                    path: 'banners/add',
                    component: () =>
                        import ("../pages/admin/BannerForm.vue")
                },
                {
                    path: 'banners/edit/:id',
                    props: true,
                    component: () =>
                        import ("../pages/admin/BannerForm.vue")
                },
                {
                    path: 'promotions',
                    component: () =>
                        import ("../pages/admin/Promotions.vue")
                },
                {
                    path: 'promotions/add',
                    component: () =>
                        import ("../pages/admin/PromotionForm.vue")
                },
                {
                    path: 'promotions/edit/:id',
                    props: true,
                    component: () =>
                        import ("../pages/admin/PromotionForm.vue")
                },
                {
                    path: 'blogs',
                    component: () =>
                        import ("../pages/admin/Blogs.vue")
                },
                {
                    path: 'blogs/add',
                    component: () =>
                        import ("../pages/admin/BlogForm.vue")
                },
                {
                    path: 'blogs/edit/:slug',
                    props: true,
                    component: () =>
                        import ("../pages/admin/BlogForm.vue")
                },
                {
                    path: 'services',
                    component: () =>
                        import ("../pages/admin/Services.vue")
                },
                {
                    path: 'address',
                    component: () =>
                        import ("../pages/admin/Address.vue")
                },
                {
                    path: 'warehouse-users',
                    component: () =>
                        import ("../pages/admin/WarehouseUser.vue")
                },
                {
                    path: 'warehouse-users/detail/:id',
                    props: true,
                    component: () =>
                        import ("../pages/admin/WarehouseDetail.vue")
                },
                {
                    path: 'admin-users',
                    component: () =>
                        import ("../pages/admin/AdminUsers.vue")
                },
                {
                    path: 'admin-users/add',
                    component: () =>
                        import ("../pages/admin/AdminUsersForm.vue")
                },
                {
                    path: 'warehouse-users/add',
                    component: () =>
                        import ("../pages/admin/WarehouseUserForm.vue")
                },
                {
                    path: 'admin-users/detail/:id',
                    props: true,
                    component: () =>
                        import ("../pages/admin/AdminUserDetail.vue")
                },
                {
                    path: 'address/add',
                    component: () =>
                        import ("../pages/admin/AddressForm.vue")
                },
                {
                    path: 'services/add',
                    component: () =>
                        import ("../pages/admin/ServicesForm.vue")
                },
                {
                    path: 'services/edit/:id',
                    component: () =>
                        import ("../pages/admin/ServicesForm.vue")
                },
                {
                    path: 'address/edit/:id',
                    props: true,
                    component: () =>
                        import ("../pages/admin/AddressForm.vue")
                },
                {
                    path: 'notification',
                    component: () =>
                        import ("../pages/admin/AdminNotification.vue")
                },
            ]
        },

        // admin
        {
            path: '/admin',
            component: () =>
                import ("../layouts/AdminLayout.vue"),
            meta: {
                requiresAuth: true
            },
            children: [{
                    path: 'dashboard',
                    name: 'DashboardAdmin',
                    component: () =>
                        import ("../pages/admin/Dashboard.vue")
                },
                {
                    path: 'my-account',
                    name: 'MyAccountAdmin',
                    component: () =>
                        import ("../components/admin/my-profile-admin.vue")
                },
                {
                    path: 'update-my-account',
                    name: 'MyAccountAdminForm',
                    component: () =>
                    import ("../components/admin/my-profile-admin-form.vue")
                },
                {
                    path: 'update-my-password',
                    name: 'MyAccountPasswordForm',
                    component: () =>
                    import ("../components/admin/my-profile-password-form.vue")
                },
                {
                    path: 'customer-packages',
                    name: 'CustomerPackagesAdmin',
                    component: () =>
                        import ("../pages/admin/CustomerPackages.vue")
                },
                {
                    path: 'customer-packages/detail/:code/:id',
                    name: 'CustomerPackagesDetailAdmin',
                    component: () =>
                        import ("../pages/admin/CustomerPackagesDetail.vue"),
                    props: true
                },
                {
                    path: 'customer-list',
                    name: 'CustomerList',
                    component: () =>
                        import ("../pages/admin/CustomerList.vue")
                },
                {
                    path: 'customer-list/detail/:id',
                    name: 'CustomerListDetail',
                    props: true,
                    component: () =>
                        import ("../pages/admin/CustomerDetail.vue")
                },
                {
                    path: 'report',
                    name: 'Report',
                    component: () =>
                        import ("../pages/admin/Report.vue")
                },
                {
                    path: 'banners',
                    name: 'Banner',
                    component: () =>
                        import ("../pages/admin/Banners.vue")
                },
                {
                    path: 'banners/add',
                    name: 'BannerForm',
                    component: () =>
                        import ("../pages/admin/BannerForm.vue")
                },
                {
                    path: 'banners/edit/:id',
                    name: 'BannerEdit',
                    props: true,
                    component: () =>
                        import ("../pages/admin/BannerForm.vue")
                },
                {
                    path: 'promotions',
                    name: 'Promotions',
                    component: () =>
                        import ("../pages/admin/Promotions.vue")
                },
                {
                    path: 'promotions/add',
                    name: 'PromotionForm',
                    component: () =>
                        import ("../pages/admin/PromotionForm.vue")
                },
                {
                    path: 'promotions/edit/:id',
                    name: 'PromotionFormEdit',
                    props: true,
                    component: () =>
                        import ("../pages/admin/PromotionForm.vue")
                },
                {
                    path: 'blogs',
                    name: 'Blogs',
                    component: () =>
                        import ("../pages/admin/Blogs.vue")
                },
                {
                    path: 'blogs/add',
                    name: 'BlogsForm',
                    component: () =>
                        import ("../pages/admin/BlogForm.vue")
                },
                {
                    path: 'blogs/edit/:slug',
                    name: 'BlogsFormEdit',
                    props: true,
                    component: () =>
                        import ("../pages/admin/BlogForm.vue")
                },
                {
                    path: 'services',
                    name: 'Services',
                    component: () =>
                        import ("../pages/admin/Services.vue")
                },
                {
                    path: 'address',
                    name: 'Address',
                    component: () =>
                        import ("../pages/admin/Address.vue")
                },
                {
                    path: 'warehouse-users',
                    name: 'WarehouseUser',
                    component: () =>
                        import ("../pages/admin/WarehouseUser.vue")
                },
                {
                    path: 'warehouse-users/detail/:id',
                    name: 'WarehouseDetail',
                    props: true,
                    component: () =>
                        import ("../pages/admin/WarehouseDetail.vue")
                },
                {
                    path: 'admin-users',
                    name: 'AdminUser',
                    component: () =>
                        import ("../pages/admin/AdminUsers.vue")
                },
                {
                    path: 'admin-users/add',
                    name: 'AdminUserAdd',
                    component: () =>
                        import ("../pages/admin/AdminUsersForm.vue")
                },
                {
                    path: 'warehouse-users/add',
                    name: 'WarehouseUserForm',
                    component: () =>
                        import ("../pages/admin/WarehouseUserForm.vue")
                },
                {
                    path: 'admin-users/detail/:id',
                    name: 'AdminDetail',
                    props: true,
                    component: () =>
                        import ("../pages/admin/AdminUserDetail.vue")
                },
                {
                    path: 'address/add',
                    name: 'AddressForm',
                    component: () =>
                        import ("../pages/admin/AddressForm.vue")
                },
                {
                    path: 'services/add',
                    name: 'ServicesForm',
                    component: () =>
                        import ("../pages/admin/ServicesForm.vue")
                },
                {
                    path: 'services/edit/:id',
                    props: true,
                    name: 'ServicesFormCust',
                    component: () =>
                        import ("../pages/admin/ServicesForm.vue")
                },
                {
                    path: 'address/edit/:id',
                    props: true,
                    name: 'AddressFormCust',
                    component: () =>
                        import ("../pages/admin/AddressForm.vue")
                },
                {
                    path: 'notification',
                    name: 'NotificationAdmin',
                    component: () =>
                        import ("../pages/admin/AdminNotification.vue")
                },
            ]
        },

        //warehouse
        {
            path: '/warehouse',
            component: () =>
                import ("../layouts/GudangLayout.vue"),
            meta: {
                requiresAuth: true
            },
            children: [{
                    path: 'dashboard',
                    name: 'DashboardGudang',
                    component: () =>
                        import ("../pages/gudang/Dashboard.vue")
                },
                {
                    path: 'customer-packages',
                    name: 'CustomerPackagesWarehouse',
                    component: () =>
                        import ("../pages/gudang/CustomerPackages.vue")
                },
                {
                    path: 'customer-packages/detail/:code/:id',
                    name: 'CustomerPackagesDetailWarehouse',
                    component: () =>
                        import ("../pages/gudang/CustomerPackagesDetail.vue"),
                    props: true
                },
                {
                    path: 'notification',
                    name: 'WarehouseNotification',
                    component: () =>
                        import ("../pages/gudang/WarehouseNotification.vue")
                },
            ]
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isLoggedIn) {
        next()
        return
      }
      next('/login')
    } else {
      next()
    }
  })

export default router;